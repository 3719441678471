<template>
  <div class="rc-border-thin">
    <Box v-if="!isLoading" :showBorder="true">
      <ValueRow> Check here every Monday to see what was released over the last week!</ValueRow>
    </Box>
    <Box v-if="!isLoading">

      <TitleRow align="left">Updates Jan 4, 2025</TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2025.01.04 </TitleRow>
            <Row :showBorder="true">
              <Column>
                <Box>
                  <ValueRow align="left">
                    We've add the ability to create a zip files containing Inspections and Hours of Service for a facility audit.
                    Simply select the Users and/or Vehicles you've been asked to provide inspections and hours of service plus a time period.
                    Save and Run the report and an email will be sent to you when the files are ready to download.
                    Inspections for a user will be in a file called 
                    Inspection_for_FIRST_LAST_from_Sept_1_2024_to_Dec_31_2024.zip and 
                    Hours_for_FIRST_LAST_from_Sept_1_2024_to_Dec_31_2024.zip for Hours of Service (if enabled). Go checkout the new <b>Reports</b> tab.
                  </ValueRow>
                </Box>
                <Box>
                  <Row>
                    <Column :width="8">
                      <Box>
                        <ValueRow>
                          <img style="border-style: solid;border-color: black;width: 75%;" src="@/assets/2025/Jan/Reports.png" width="90%" />
                        </ValueRow>
                      </Box>
                    </Column>
                    <Column :width="7">
                      <ValueRow>
                          <img style="border-style: solid;border-color: black;width: 75%;" src="@/assets/2025/Jan/CreateReport.png" width="90%" />
                        </ValueRow>
                    </Column>
                  </Row>
                </Box>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>

      <TitleRow align="left">Updates Sept 7, 2024</TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2024.09.07 </TitleRow>
            <Row :showBorder="true">
              <Column :width="8">
                <Box>
                  <ValueRow align="left">
                    We've updated the Inspections page! You can now pick the number of items
                    you'd like to see on a page 10,20,50 or 100. You can also jump to a specific
                    page. 
                  </ValueRow>
                  <ValueRow align="center">
                    <img style="border-style: solid;border-color: black;" src="@/assets/2024/whatsnew/Sep/InspectionsUpdate.png" width="90%" />
                  </ValueRow>
                </Box>
              </Column>
              <Column :width="7">
                <Box>
                  <ValueRow align="left">
                    Additionally we've made it easier to navigate by show details in a 
                    dialog box that allows you to quick look at the details of an inspection
                    and return to exactly where you were before!
                  </ValueRow>
                  <ValueRow align="center">
                    <img style="border-style: solid;border-color: black;" src="@/assets/2024/whatsnew/Sep/InspectionsFloatingDetails.png" width="90%" />
                  </ValueRow>
                  <ValueRow align="left">
                    We'll be changing Users, Vehicles and Trailers, Checklists, 
                    Hours of Service and Compliance views to these same features.
                  </ValueRow>
                </Box>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>
      <SpacerRow />

      <TitleRow align="left">Updates Sept 1, 2024</TitleRow>
      <Row :showBorder="true">
        <Column :width="7">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2024.09.01 </TitleRow>
            <Row :showBorder="true">
              <Column>
                <Box>
                  <ValueRow align="left">
                    Changed the Sign-In and make it easier to find the Create Account button
                    <img src="@/assets/2024/whatsnew/Aug/SignIn2024.png" width="90%" />
                  </ValueRow>
                </Box>
              </Column>
            </Row>
          </Box>
        </Column>
        <Column :width="8">
          <Box>
            <TitleRow :subtitle="true" align="left"> App version 2.6 </TitleRow>
            <Row :showBorder="true">
              <Column :width="8">
                <Box>
                  <ValueRow>
                    Added a today and previous days separately and previous days tab can now add history, 14 days at a time, into the app for viewing and updating.
                    <img src="@/assets/2024/whatsnew/Aug/iPhoneHoSOverview.png" width="80%" />
                  </ValueRow>
                </Box>
              </Column>
              <Column :width="7">
                <Box>
                  <ValueRow>
                    Previous days tab can now show you missing hours at a quick glance as well, you can load more days to fix older Hours of Service records.
                    <img src="@/assets/2024/whatsnew/Aug/iPhoneHoSList.png" width="80%" />
                  </ValueRow>
                </Box>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>
      <SpacerRow />

      <TitleRow align="left">Updates Feb 9, 2024</TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2024.02.09 </TitleRow>
            <ValueRow align="left">
             Compliance View can now travel back in time! Use the arrow in the Action sections 
             to go back 2 weeks at a time.
             <img src="@/assets/2024/whatsnew/feb/ComplianceView.png" width="90%" />
            </ValueRow>
          </Box>
        </Column>
      </Row>
      <SpacerRow />
      
      
      <TitleRow align="left"> Updates Dec 15, 2023 </TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2.14.22 </TitleRow>
            <ValueRow align="left">
            Fixed a typo on the signout dialog - for April :) 
            </ValueRow>
          </Box>
        </Column>
      </Row>
      <SpacerRow />
      
      <TitleRow align="left"> Updates Nov 15, 2023 </TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2.11.0 </TitleRow>
            <ValueRow align="left">
              Checklists are here! What is a checklist you ask? It's an additional set
              of questions that are available for a driver to answer when completing
              and inspection! If you want to have checklists enabled give us a call 
              send us an email and we'll turn it on for your company.<br>
              <center>Phone must be at least version <b>2.4.3</b> and if you're interested call or email <a href="mailto:support@readychek.com">Support</a> (Michelle) to have it turned on for your company!</center>
            </ValueRow>
            <Row>
            <Column :width="9" :showBorder="false">
              <Box :showBorder="true">
                <ValueRow align="left">When enabled a new menu option will appear 
                called Checklists, this is where you'll create and edit checklists. 
                </ValueRow>
                <Row>
                  <Column :width="15">
                    <center>
                      <img src="@/assets/2023/whatsnew/nov/ChecklistListView.png" width="90%" />
                    </center>
                  </Column>
                </Row>
              </Box>
              <Box :showBorder="true">
                <ValueRow align="left">When creating your first checklist you will specify
                a set of questions that you'd like the driver to answer. These questions
                are mandatory on a vehicle inspection. After you create a checklist you will
                need to go to the vehicle list, edit a vehicle and add the checklist to 
                a vehicle.
                </ValueRow>
                <Row> 
                 <Column :width="15">
                    <center>
                      <img src="@/assets/2023/whatsnew/nov/ChecklistDetailView.png" width="90%" />
                    </center>
                 </Column>
                </Row>
              </Box>
            </Column>
            <Column :width="3" :showBorder="true">
              <Box>
                <ValueRow align="left">A Checklist will appear on all vehicle Inspections
                for a specific vehicle after the schedule questions.
                </ValueRow>
                <SpacerRow />
                <SpacerRow />
              </Box>
              <Box>
                <Row>
                  <Column :width="15"> 
                  <center>
                    <img src="@/assets/2023/whatsnew/nov/ChecklistInspection.png" width="90%" />
                  </center>
                  </Column>
                </Row>
              </Box>
            </Column>
            <Column :width="3" :showBorder="true">
              <Value align="left">Bonus Feature - Last used trailers. The app
              will now record the last used trailers for each vehicle. When you
              do an inspection you can simply press the add button to re-add
              the trailer. This will save you 3 clicks!</Value>
              <center>
                <img src="@/assets/2023/whatsnew/nov/LastUsedTrailers.png" width="90%" />
              </center>
            </Column>
            
          </Row>
          </Box>
        </Column>
      </Row>
      
      <SpacerRow />
      
      <TitleRow align="left"> Updates Nov 2, 2023 </TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
            <TitleRow :subtitle="true" align="left"> Portal version 2.10.39 </TitleRow>
            <ValueRow align="left">
              We've added a new filter to the inspection list that will let you find
              an inspection ON or BEFORE the selected date.
              We did this so that you could easily go back and find what the odometer 
              of a vehicle was a year ago. 
              Click on the small icon to the right to show the calendar
            </ValueRow>
            <Row>
              <Column :width="15" :showBorder="false">
                <Value align="left"></Value>
                <center>
                  <img src="@/assets/2023/whatsnew/nov/InspectionDatePicker.png" width="90%" />
                </center>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>
      
      <SpacerRow />
      
      <TitleRow align="left"> Updates Oct 23, 2023 </TitleRow>
      <Row :showBorder="true">
        <Column :width="15">
          <Box :showBorder="false">
          <TitleRow :subtitle="true" align="left"> Portal version 2.10.37 </TitleRow>
          <ValueRow align="left">
            It's been awhile since our last update! We are working on 
            a new feature that should be out by the end of November.
            In the meantime we've updated the portal and make it a 
            little wider to allow more information.
            We had a problem when filling out a drivers address the 
            email address would change unexpectedly because of the 
            browsers auto complete feature, this autocomplete feature 
            has been disabled. 
            Finally we fixed a few problems with the inspection email.
          </ValueRow>
          
          <Row>
            <Column :width="5" :showBorder="true">
              <Value align="left"> The new remarks dialog allows you to quickly add a remark or comment to an inspection without reloading the inspection.</Value>
              <center>
                <img src="@/assets/2023/whatsnew/oct/RemarksDialog.png" width="90%" />
              </center>
            </Column>
            <Column :width="5" :showBorder="true">
              <Value align="left">A better looking email dialog and we used an mail image.</Value> 
              <center>
                <img src="@/assets/2023/whatsnew/oct/EmailDialog.png" width="90%" />
              </center>
            </Column>
            <Column :width="5" :showBorder="true">
              <Value align="left">Nice simple sign out dialog</Value>
              <center>
                <img src="@/assets/2023/whatsnew/oct/SignOutDialog.png" width="90%" />
              </center>
            </Column>
            
          </Row>
          
          </Box>
        </Column>
      </Row>
            
      <SpacerRow />
      
      <TitleRow align="left"> Updates July 20, 2023 </TitleRow>
      <Row>
        <Column :width="15">
          <Box :showBorder="true">
          <TitleRow :subtitle="true" align="left"> Portal version 2.10.24 </TitleRow>
          <ValueRow align="left">
            So we had our first outage today in a very long time. At approximately
            10am on July 20 our main sign-on service stopped responding to requests. This caused
            web portal and app users to experience delays or be prevented from using the app
            entirely. At around 11am a temporary solution was added to allow users to sign-in
            again and a proper solution added around noon. All systems are functioning as expected now.
            We are very sorry for the interruption and we are committed to producing a quality 
            product that is easy to use and reliable and we'll do our best to 
            prevent interruptions like this in the future. If you have any questions at all please 
            email us at support@readychek.com.<br>
            Chad and Michelle
          </ValueRow>
          </Box>
        </Column>
      </Row>
            
      <SpacerRow />

      <TitleRow align="left"> Updates July 14, 2023 </TitleRow>
      <Row>
      <Column :width="7">
        <Box :showBorder="true">
          <TitleRow :subtitle="true" align="left"> Portal version 2.10.20 </TitleRow>
          <ValueRow align="left">
            We've updated the look of our emails, they have a nice header, 
            have more details and provide
            useful links to our social media accounts.
          </ValueRow>
          <ValueRow align="left">
            You can now add remarks and comments to an inspection from the Portal!
            Simply visit the Inspections page or any Inspection Detail/Repair page to add
            a remark.
          </ValueRow>
          <Row>
            <Column :width="15">
              <center>
                <img src="@/assets/2023/whatsnew/InspectionRemarks.png" width="90%" />
              </center>
            </Column>
          </Row>
        </Box>
      </Column>
      <Column :width="8">
        <Box :showBorder="true">
          <TitleRow :subtitle="true" align="left"> App version 2.3.2 </TitleRow>
            <ValueRow align="left">
              It's been a while since we had an update but I wanted you to know that 
              Michelle and I have been hard at work updating the phone apps 
              for the next set of features. When the next release is ready it'll
              be even easier to create an inspection and to identify non applicable 
              components.
            </ValueRow>
        </Box>
      </Column>
      </Row>
      
      <TitleRow align="left"> Updates April 16, 2023 </TitleRow>
      <Row>
        <Column :width="7">
          <Box :showBorder="true">
            <TitleRow :subtitle="true" align="left"> Portal version 2.10.8 </TitleRow>
          
            <ValueRow align="left">
              You can now see what features you are using and the associated costs. 
            </ValueRow>
            <Row>
              <Column :width="15">
                <center>
                  <img src="@/assets/2023/whatsnew/PlanInformation.png" width="90%" />
                </center>
              </Column>
            </Row>
                
            <ValueRow align="left">
              Remember you're responsible for tracking your drivers hours of service and 
              duty status changes. If you want an easy solution, add our Hours of Service
              feature and let your drivers do it for you.
            </ValueRow>
              
            <SpacerRow />
              
            <TitleRow :subtitle="true" align="left"> Address Information </TitleRow>
            <ValueRow align="left">
              We've updated the Company page to separate the official registered address of your
              business from the app HQ button address. It is important that this company 
              information matches the 
              information on your commercial motor vehicle registration. If you are 
              operating as a CVOR company, this information must match your 
              CVOR documentation.
            </ValueRow>
          
            <Row>
              <Column :width="15">
                <center>
                  <img src="@/assets/2023/whatsnew/CompanyEdit.png" width="90%" />
                </center>
              </Column>
            </Row>
              
            <TitleRow :subtitle="true" align="left"> Email Subject </TitleRow>
            <ValueRow align="left">
              We've updated the email subject line to include the operations that
              caused the email. Please update phones to at least ReadyChek app 
              version 2.3.0
            </ValueRow>
              
            <Row>
              <Column :width="15">
                <center>
                  <img src="@/assets/2023/whatsnew/Emails.png" width="90%" />
                </center>
              </Column>
            </Row>
              
          </Box>
        </Column>
            
        <Column :width="8">
          <Box :showBorder="true">
            <TitleRow :subtitle="true" align="left"> App version 2.3.0 </TitleRow>
            <Row>
              <Column :width="5" :showBorder="false">
                <Value align="left"> The vehicle details page now has a button for the driver to
                display the full vehicle schedule </Value>
              </Column>
              
              <Column :width="5" :showBorder="false">
                <Value> The schedule page allows the driver to search and review
                all of the system and components that must be reviewed on every
                pre-trip inspection</Value>
              </Column>

              <Column :width="5" :showBorder="false">
                <Value> Finally we've enhanced the MTO Page and display the full schedule
                with all system and components. This was done based on feedback from MTO
                road side inspections that have happened since January 2023</Value>
              </Column>
            </Row>
            <Row>
              <Column :width="5" :showBorder="false">
                <center>
                  <img src="@/assets/2023/whatsnew/VehicleDetailsSchedule.jpg" width="90%" />
                </center>
              </Column>
              
              <Column :width="5" :showBorder="false">
                <center>
                  <img src="@/assets/2023/whatsnew/Schedule.jpg" width="90%" />
                </center>
              </Column>

              <Column :width="5" :showBorder="false">
                <center>
                  <img src="@/assets/2023/whatsnew/MtoDisplay.jpg" width="90%" />
                </center>
              </Column>
            </Row>
          </Box>
        </Column>
      </Row>
        
      <TitleRow align="left"> Version 2.9.38 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>
            You can now see when drivers licenses expire because a valid license is required to operate
            a vehicle.
          </Value>
          <center><img src="@/assets/2022/whatsnew/LicenseExpiryWarningMessage.png" width="75%" /></center>
          
          <br>
          <Value>
            As well, if a vehicle is missing its registered
            gross weight (RGW) value. We will, in a future release, use this vehicles RGW along with the
             drivers license to help a driver know what trucks and trailers can be driven without breaking 
             the law.
          </Value>
          <center><img src="@/assets/2022/whatsnew/WeightWarningMessage.png" width="75%" /></center>
        </Column>
      </Row>
      
      <TitleRow align="left"> Version 2.9.34 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>
            You can now pay automatically each month by credit card!
            Visit the Billing page in the operator portal where you can add 
            a valid credit card.<br>
            As always you will receive your invoice the first week of the month.
            Your credit card will be charged between the 15th and the 30th.
          </Value>
          <center><img src="@/assets/2022/whatsnew/PayByCreditCard2.png" width="75%" /></center>
        </Column>
      </Row>
        
      <SpacerRow />
      <TitleRow align="left"> Version 2.9.31 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>
            There is a single view to help you with compliance, see who has done inspections or 
            who is missing hours of service. 
            Send reminders to drivers who have not yet completed their hours for the week.
          </Value>
          <center><img src="@/assets/2022/whatsnew/ComplianceView.jpg" width="75%" /></center>
        </Column>
      </Row>
      
      <SpacerRow />  
      <TitleRow align="left"> Version 2.9.22 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>
            You can now add a users drivers license class and expiry date to their profile. 
            In an upcoming release of the app this information will be used to ensure your
            drivers do not leave with a vehicle and trailer combination that does not comply
            with provincial regulations.
          </Value>
          <center><img src="@/assets/2022/whatsnew/LicenseDetails.png" width="75%" /></center>
        </Column>
      </Row>
      
      <SpacerRow />
      <TitleRow align="left"> Version 2.9.19 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>
            You monthly bill can now be easily paid online! 
            At the bottom of the monthly there is a new green button that quickly
            take you to pay your balance in our online payment systems. 
          </Value>
          <center><img src="@/assets/2022/whatsnew/PayOnline.png" width="25%" /></center>
        </Column>
      </Row>
      <Row :showBorder="true">
        <Column>
          <Value>You can now see the total number of Kilometers on all vehicles. This value is
          display on the Home page in the Operator Portal</Value>
          <center><img src="@/assets/2022/whatsnew/OdometerTotal.png" width="50%" /></center>
        </Column>
      </Row>
      <Row :showBorder="true">
        <Column>
          <Value>New separate invoices link that lets you quickly see all invoices</Value>
          <center><img src="@/assets/2022/whatsnew/InvoiceAndBilling2.png" width="75%" /></center>
        </Column>
      </Row>
        
      <SpacerRow />
        
      <TitleRow align="left"> Version 2.9.7 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value>You can now filter Inspections, Hours of Service and Check-Ins for the last 30 days</Value>
          <center><img src="@/assets/2022/whatsnew/FilterBy30Days.png" width="75%" /></center>
        </Column>
      </Row>
      <Row :showBorder="true">
        <Column>
          <Value>If the filters you select have no matches we will let you know that too!</Value>
          <center><img src="@/assets/2022/whatsnew/FilterHelp.png" /></center>
        </Column>
      </Row>
      
      <SpacerRow />
        
      <TitleRow align="left"> Version 2.9.3 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value> To all our customers who pay by credit card you'll see a heartfelt thank-you message and for everyone else, you too will see a heartfelt thank-you... soon! </Value>
          <center><img src="@/assets/2022/whatsnew/CCThankyou.png" width="25%" /></center>
        </Column>
      </Row>
      
      <SpacerRow />
        
      <TitleRow align="left"> Version 2.9.0 </TitleRow>
      <Row :showBorder="true">
        <Column>
          <Value> Changed the look and feel to make it easier on the eyes </Value>
          <Value> Rounded edges on titles and buttons more colours to easily separate blocks of information</Value>
        </Column>
      </Row>
          
      <Row :showBorder="true">
        <Column>
          <Value> Home now shows insights into your users and vehicles for today </Value>
          <center><img src="@/assets/2022/whatsnew/InsightsForToday.png" width="90%" /></center>
          <Value> All the information you see here is clickable, it will take you to more detailed information!</Value>
        </Column>
      </Row>
        
      <Row :showBorder="true">
        <Column>
          <Value> Home now shows insights on usage for the month </Value>
          <center><img src="@/assets/2022/whatsnew/InsightsForMonth.png" width="90%" /></center>
        </Column>
      </Row>
        
      <Row :showBorder="true">
        <Column>
          <Value> Home now shows insights usage over the last 15 days and months </Value>
          <center><img src="@/assets/2022/whatsnew/InsightsOverTime.png" width="90%" /></center>
        </Column>
      </Row>
    </Box>
    <Box>
        <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    </Box>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

//import ConstUtils   from '@/utils/ConstUtils.js';
import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Catelog from "@/domain/session/CanadianEnglish.js";

import Box    from '@/portals/shared/library/box/Box.vue';
import Row    from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
//import Button from '@/portals/shared/library/button/Button.vue';

//import Title from '@/portals/shared/library/title/Title.vue';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import Value from '@/portals/shared/library/value/Value.vue';
import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue"; 


export default {
  name: 'portal-operator-home-whats-new',
  components: {
//    Button,
    LoadingRow,
    Box,
    Row,
    Column,
//    Title,
    TitleRow,
    Value,
    ValueRow,
    SpacerRow,
//    Button,
  },
  data() {
    return {
      isAdmin: false,
      error: null,
      Keys: Catelog.KEYS.Home,
      Catelog: new Catelog(),
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      showSessionTimeoutDialog: false,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
      ConnectionUtils.ensureCompanyLoaded(this, "whatsnew");
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  },
}
</script>